import { every, isEmpty, last, size, split } from 'lodash';
import { utils, writeFileXLSX } from 'xlsx';

export const downloadExcel = (data, fileName) => {
  const ws = utils.json_to_sheet(data);
  // console.log(ws, 'wssss');

  const wb = utils.book_new();
  // console.log(wb, 'wbbbb');

  utils.book_append_sheet(wb, ws, 'Data');
  // console.log(utils.book_append_sheet(wb, ws, 'Data'));

  writeFileXLSX(wb, `${fileName}.xlsx`);
  // console.log(writeFileXLSX(wb, `${fileName}.xlsx`), 'pppppppp');
};

export const numberWithCommas = (x) => {
  if (x === 0) return 0;
  if (!x) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const removeCommas = (s) => s.toString().replace(/,/g, '');
export const validateDate = (x) => {
  var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;

  if (x.match(pattern)) {
    return;
  }

  return 'ENTER IN DD/MM/YYYY FORMAT';
};

export const validateId = (value) => {
  if (size(value) !== 9) {
    return 'המזהה אינו חוקי';
  }
  return '';
};

// this function will validate mobile number
export const validateMobileNumber = (value) => {
  let err = validateNumber(value);
  if (isEmpty(err) && size(value) !== 10) {
    err = 'מספר הטלפון אינו חוקי';
  }
  return err;
};

// this function will validate email
export const validateEmail = (value) => {
  const emailRegex =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(value) ? '' : 'האימייל אינו חוקי';
};

// this function validates link and returns true if it is valid
export const validateUrl = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return pattern.test(url) ? '' : 'הזן כתובת אתר חוקית';
};

// this functio will validate empty field
export const validateEmptyField = (field) => (isEmpty(field) ? 'שדה לא יכול להיות ריק' : '');

export const handlebudget = (budget, valuation) => {
  if (isEmpty(validateNumber(budget))) {
    if (parseFloat(valuation) > parseFloat(budget)) {
      return `מינימום, ${valuation}`;
    } else {
      return '';
    }
  } else {
    return validateNumber(budget);
  }
};

// this function will check if errors object is empty or not
export const isEmptyErrors = (errors) => every(errors, (value) => isEmpty(value));

// this function will check if field is number or not
export const validateNumber = (value) => {
  const pattern = /^\d+$/;
  return pattern.test(value) ? '' : 'הכנס מספר';
};

// this function will convert number to two digit
export const convertToTwoDigit = (value) => {
  if (parseInt(value) < 10) {
    return `0${value}`;
  }

  return value;
};

// this fucntion will return the current formatted time
export const getFormattedCurrentTime = () => {
  const today = new Date();
  const date = convertToTwoDigit(today.getDate());
  const month = convertToTwoDigit(parseInt(today.getMonth() + 1));
  const year = today.getFullYear();
  const hours = convertToTwoDigit(today.getHours());
  const minutes = convertToTwoDigit(today.getMinutes());

  return `${date}/${month}/${year} , ${hours}:${minutes}`;
};

export const getFormattedTime = (timestamp) => {
  let seconds = timestamp / 1000;
  const minutes = parseInt(seconds / 60);
  seconds = seconds - minutes * 60;
  return `${convertToTwoDigit(minutes)}:${convertToTwoDigit(parseInt(seconds))}`;
};

export const getFormattedCurrentDate = () => {
  const today = new Date();
  const date = convertToTwoDigit(today.getDate());
  const month = convertToTwoDigit(parseInt(today.getMonth() + 1));
  const year = today.getFullYear();
  return `${date}/${month}/${year}`;
};

// this function will return the image extension
export const getImageExtension = (imageObject) => {
  const imageTyep = imageObject.type;
  const imageArray = split(imageTyep, '/');
  return last(imageArray);
};

export const convertTimestampIntoDate = (timestamp) => {
  if (!timestamp && !(typeof timestamp === 'string' || typeof timestamp === 'number')) return '';
  const time = parseInt(timestamp) * 1000;
  if (time < 0) return '';
  const dateobj = new Date(time);
  const date = convertToTwoDigit(dateobj.getDate());
  const month = convertToTwoDigit(parseInt(dateobj.getMonth() + 1));
  const year = dateobj.getFullYear();
  return `${date}/${month}/${year}`;
};

// combined function for both csv and txt
// export const downloadFile = (data, fileName, type = 'csv') => {
//   // type = csv or txt
//   let typeToUse = type === 'csv' ? 'csv' : 'plain';
//   if (data === false) return;

//   const normalizedData = data.replace(/\r?\n/g, '\r\n').trim();

//   const utf8Bom = '\uFEFF';

//   const blob = new Blob([utf8Bom + normalizedData], { type: `text/${typeToUse};charset=utf-8;` });

//   // Create a URL for the Blob
//   const url = window.URL.createObjectURL(blob);

//   const a = document.createElement('a');
//   a.href = url;
//   // a.download = 'report.csv'; // The name of the file
//   a.download = `${fileName}.${type}`; // The name of the file

//   // Append anchor to the document body
//   document.body.appendChild(a);

//   // Trigger click event on the anchor to start the download
//   a.click();

//   // Remove anchor from the document body
//   document.body.removeChild(a);

//   // Revoke the object URL to free up memory
//   window.URL.revokeObjectURL(url);
// };

// somewhat works
// export const downloadFile = (data, fileName, type = 'csv') => {
//   // type = csv or txt
//   let typeToUse = type === 'csv' ? 'csv' : 'plain';
//   if (data === false) return;

//   let normalizedData = data.replace(/\r?\n/g, '\r\n').trim();

//   if (type === 'txt') {
//     const lines = normalizedData.split('\r\n');

//     const processedLines = lines.map(
//       (line) =>
//         // Add LTR mark before each cell
//         '\u200E' + line.split('\t').join('\u200E\t\u200E'),
//     );

//     normalizedData = processedLines.join('\r\n');
//   }

//   const utf8Bom = '\uFEFF';
//   const blob = new Blob([utf8Bom + normalizedData], { type: `text/${typeToUse};charset=utf-8;` });
//   const url = window.URL.createObjectURL(blob);

//   const a = document.createElement('a');
//   a.href = url;
//   a.download = `${fileName}.${type}`;

//   document.body.appendChild(a);
//   a.click();
//   document.body.removeChild(a);
//   window.URL.revokeObjectURL(url);
// };

export const downloadFile = (data, fileName, type = 'csv') => {
  // type = csv or txt
  let typeToUse = type === 'csv' ? 'csv' : 'plain';
  if (data === false) return;

  let normalizedData = data.replace(/\r?\n/g, '\r\n').trim();

  if (type === 'txt') {
    const lines = normalizedData.split('\r\n');

    const processedLines = lines.map((line) => {
      const cells = line.split('\t');
      return cells
        .map((cell) => {
          // First remove all existing directional marks
          let cleanCell = cell.replace(/[\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, '');

          // Find Hebrew text segments
          const hebrewRegex = /[\u0590-\u05FF]+/g;
          let lastIndex = 0;
          let result = '';
          let match;

          while ((match = hebrewRegex.exec(cleanCell)) !== null) {
            // Add non-Hebrew text before this match with LTR mark
            result += '\u200E' + cleanCell.slice(lastIndex, match.index);
            // Add Hebrew text with RTL mark
            result += '\u200F' + match[0] + '\u200E';
            lastIndex = match.index + match[0].length;
          }

          // Add remaining text with LTR mark
          result += '\u200E' + cleanCell.slice(lastIndex);

          // Wrap entire cell in LTR isolate
          return '\u2066' + result + '\u2069';
        })
        .join('\t');
    });

    normalizedData = processedLines.join('\r\n');
  }

  const utf8Bom = '\uFEFF';
  const blob = new Blob([utf8Bom + normalizedData], { type: `text/${typeToUse};charset=utf-8;` });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.${type}`;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export function downloadArrayAsTxtTabDelimited(data, filename = 'export.txt') {
  // Create BOM for UTF-16LE
  const bom = new Uint8Array([0xff, 0xfe]);

  // Function to check if a string is a date in format DD/MM/YYYY
  // const isDate = (str) => /^\d{2}\/\d{2}\/\d{4}$/.test(str);

  // Function to pad non-date values with a space for right alignment
  // const padValue = (value) => {
  //   value = String(value);
  //   return isDate(value) ? value : ' ' + value;
  // };

  // Convert 2D array to tab-delimited string with padding
  const content = data.map((row) => row.map((cell) => cell).join('\t')).join('\r\n');

  // Convert string to UTF-16LE
  const encoder = new TextEncoder();
  const utf8Content = encoder.encode(content);
  const utf16Content = new Uint16Array(utf8Content.length);

  for (let i = 0; i < utf8Content.length; i++) {
    utf16Content[i] = utf8Content[i];
  }

  // Combine BOM and content
  const finalContent = new Uint8Array([...bom, ...new Uint8Array(utf16Content.buffer)]);

  // Create blob and download
  const blob = new Blob([finalContent], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

export function downloadArrayAsCsv(data, filename = 'export.csv') {
  // Convert 2D array to CSV string
  const content = data.map((row) => row.map((cell) => `"${cell}"`).join(',')).join('\r\n');

  // Convert string to UTF-8
  const encoder = new TextEncoder();
  const utf8Content = encoder.encode(content);

  // Create blob and download
  const blob = new Blob([utf8Content], { type: 'text/csv;charset=UTF-8' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
